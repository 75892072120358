import { Agencia } from "../cadastro/agencia.model";
import { Empresa } from "../cadastro/empresa.model";
import { Usuario } from "../usuario.model";

export class Notificacao {
    public Id: number;
    public Titulo: string;
    public Descritivo: string;
    public GruposNotificacoes: GrupoNotificacao[] = [];
}

export class GrupoNotificacao {
    public Id: number;
    public Nome: string;
    public Notificacoes: Notificacao[] = [];
    public Agencias: Agencia[] = [];
    public Empresas: Empresa[] = [];
    public Usuarios: Usuario[] = [];
}