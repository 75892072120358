import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Dispositivos } from "../../model/cadastro/dispositivos.model";
import { Observable } from "rxjs";
import { API } from "../../app.config";

@Injectable()
export class DispositivosService {
    private headers = new HttpHeaders().append("Content-Type", "application/json");

    constructor(
        private http: HttpClient
    ) { }

    excluirDispositivo(dispositivoId: number): Observable<boolean> {
        return this.http.delete<boolean>(`${API}/api/dispositivos/${dispositivoId}`, { headers: this.headers });
    }

    editarDispositivo(dispositivo: Dispositivos): Observable<Dispositivos> {
        return this.http.put<Dispositivos>(`${API}/api/dispositivos`, dispositivo, { headers: this.headers });
    }
}