import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BuscaServicoRQ, TIPO } from '../../model';

import { TURISMO_SERVICO } from '../../app.config';
import { ITENSPORPAGINA } from '../../app.config';
import { CLIENT_ID } from '../../app.config';

@Injectable()
export class ServicoService {
  constructor(private http: HttpClient) { }

  buscarTransfer(busca: BuscaServicoRQ, pagina: Number): Observable<any> {
    busca.TipoServico = TIPO.TRANSFER;
    return this.http.post<any>(`${TURISMO_SERVICO}/api/Servico/busca?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  buscarTicket(busca: BuscaServicoRQ, pagina: Number): Observable<any> {
    busca.TipoServico = TIPO.INGRESSO;
    return this.http.post<any>(`${TURISMO_SERVICO}/api/Servico/busca?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  buscarServicos(busca: BuscaServicoRQ, pagina: Number, promocional: boolean = false): Observable<any> {
    busca.TipoServico = TIPO.TODOS;
    return this.http.post<any>(`${TURISMO_SERVICO}/api/Servico/busca?Pagina=${pagina}&ItensPorPagina=21`, busca, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  removerServico(servico: any): Observable<any> {
    return this.http.delete<any>(`${TURISMO_SERVICO}/api/Servico/${servico.Id}`);
  }

  buscarPolitica(servico: any, data: string): Observable<any> {
    return this.http.patch<any>(`${TURISMO_SERVICO}/api/servico/politica/${servico.ReservaId}/${servico.Id}/${data}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  reservarServico(politica: any): Observable<any> {
    return this.http.post<any>(`${TURISMO_SERVICO}/api/servico/reservar/${politica.Servico.Id}`, politica, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  cancelarServico(servico: any): Observable<any> {
    return this.http.post<any>(`${TURISMO_SERVICO}/api/servico/cancelar/${servico.ReservaId}/${servico.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  buscarServicoPorId(id): Observable<any> {
    return this.http.get<any>(`${TURISMO_SERVICO}/api/Servico/${id}`, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  alterarStatus(alteracao: any): Observable<any> {
    return this.http.put<any>(`${TURISMO_SERVICO}/api/servico/${alteracao.Id}`, alteracao, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  calcularListagem(servico: any): Observable<any> {
    return this.http.post<any>(`${TURISMO_SERVICO}/api/servico/calcularListagem`, servico, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  atualizarStatusServico(servico: any): Observable<any> {
    return this.http.patch<any>(`${TURISMO_SERVICO}/api/servico/atualizar/${servico.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
  }
}
