import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Banner } from '../../model';
import { API } from '../../app.config';

@Injectable()
export class BannerService{
  constructor(private http: HttpClient){}

  ListaPorParametroPaginada(param: string, local: string, pagina: Number): Observable<any> {
    if(local == null || local === undefined || local === ""){
      local = "TODOS";
    }

    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/banners/listaPaginada/${param.trim()}?Filtro=${local}&Pagina=${pagina}&ItensPorPagina=${12}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/banners/listaPaginada?Filtro=${local}&Pagina=${pagina}&ItensPorPagina=${12}`, { headers: headers });
    }
  }

  uploadBanner(arquivo: any = null, objeto: Banner):  Observable<any> {
    const headers = new HttpHeaders().append('noloader', 'noloader');
    let stringfyITEM = JSON.stringify(objeto)
    const formData = new FormData();
    formData.append('Arquivo', arquivo);
    formData.append('Dados', stringfyITEM);
    return this.http.post(`${API}/api/banners/upload`, formData, { headers: headers });
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/banners/delete/${id}`);
  }

  buscarPorId(id: Number): Observable<any> {
    return this.http.get<any>(`${API}/api/banners/${id}`);
  }

  buscarPorLocal(local: String): Observable<any> {
    return this.http.get<any>(`${API}/api/banners/Local/${local}`);
  }

  buscarPorSite(local: String): Observable<any> {
    return this.http.get<any>(`${API}/api/banner/${local}`);
  }
}
