import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoginService } from './login.service';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginLightComponent } from '../auth/login-light/login-light.component';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService, private modalService: NgbModal,private router: Router) { }

  // intercept(request: any, next: HttpHandler): Observable<HttpEvent<any>> {
  //   // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

  //   const loader = request.headers.keys();
  //   const noauth = (loader.indexOf('noauth') != -1);
  //   const currentUrl = this.router.url;
  //   if (!noauth && this.loginService.isLoggedIn()) {
  //     const authRequest = request.clone({ setHeaders: { 'Authorization': 'Bearer ' + this.loginService.user().access_token } });
  //     return next.handle(authRequest).pipe(
  //       catchError((error: HttpErrorResponse) => {
  //         if (error.status == 401) {
  //           // this.openModalLogin();
  //           this.loginService.logout();
  //           window.location.reload();
  //         }
  //         return throwError(error);
  //       })
  //     );
  //   } else {
  //     if (noauth) {
  //       request.headers = request.headers.delete('noauth');
  //     }
  //     return next.handle(request);
  //   }
  // }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      let headers = this.rebuildHeaders(request.headers);
      const noauth = headers.has('noauth');

      let modifiedRequest = request;

      if (!noauth && this.loginService.isLoggedIn()) {
        const token = this.loginService.user().access_token || '';
        modifiedRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else if (noauth) {
        const headersWithoutNoAuth = headers.delete('noauth');
        modifiedRequest = request.clone({ headers: headersWithoutNoAuth });
      }

      return next.handle(modifiedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.loginService.logout();
          }
          return throwError(error);;
        })
      );
    } catch (error) {
      console.error('Error in AuthInterceptor:', error);
      return next.handle(request);
    }
  }

  private rebuildHeaders(headers: HttpHeaders): HttpHeaders {
    if (!headers) {
      return new HttpHeaders();
    }

    // Checa se existe lazyUpdate
    const lazyUpdates = (headers as any).lazyUpdate;

    if (!lazyUpdates || !Array.isArray(lazyUpdates)) {
      // Retorna os headers originais se não houver lazyUpdate
      return headers;
    }

    // Reconstrói os headers a partir de lazyUpdate
    return lazyUpdates.reduce((acc, update) => {
      if (update.name && update.op === 's') { // 's' para "set"
        return acc.set(update.name, update.value || '');
      }
      return acc;
    }, new HttpHeaders());
  }

  openModalLogin() {
    this.modalService.open(LoginLightComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true, keyboard: false });
  }


}
