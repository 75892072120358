import { Agencia } from '../cadastro/agencia.model';
import { Cidade } from '../endereco.model';

export class ServicoOffline{

  public Id: number;
  public Titulo: string;
  public Titulo_Sp: string;
  public Titulo_En: string;

  public Descricao: string;
  public Descricao_Sp: string;
  public Descricao_En: string;

  public Longitude: string;
  public Latitude: string;
  public TempoDuracao: string;

  public ManutencaoPeriodo : OfflineServicoManutencaoPeriodo[];
  public Destaque: any;
  public Ativo: any;
  public Combo: boolean = false;
  public DiasSemana: string;
  public DataUnica: any;
  public PrazoCancelamento: number;
  public PoliticaCancelamento: string;
  public PoliticaCancelamento_Sp: string;
  public PoliticaCancelamento_En: string;
  public OfflineServicoFornecedorId: number;
  public OfflineServicoTipo: ServicoOfflineTipo = new ServicoOfflineTipo();
  public OfflineServicoFornecedor : ServicoOfflineFornecedor = new ServicoOfflineFornecedor();
  public Tipo: any;
  public Categoria: any;
  public Alteracoes: any;
  public CidadesVinculas: Cidade[];
  public AgenciasExclusivas: Agencia[];
  public Imagens: OfflineServicoImagens[];
  public Tarifas : ServicoOfflineTarifa[];
  public Perguntas : ServicoOfflinePergunta[];
  public MarkupOfflineServico : MarkupOfflineServico[];
  public OfflineServicoTipoId : number;
  public Pacote : any;
  public ERPId : any;
  WebServices: any[];
  public DiasAntecedenciaReserva : any;
}

export class ServicoOfflineFornecedor{
  public Id: number;
  public Cidade: Cidade = new Cidade();

  public RazaoSocial: String;
  public Nome: String;
  public CNPJ: String;
  public WebSite: String;
  public Email: String;
  public EmailReserva: String;
  public Telefone: String;
  public SegundoTelefone: String;
  public ERPId: any;
  public TemIRRF : any;
  public Servicos: ServicoOffline[];
  public MoedaId: number;
  public Cep: any;
  public Logradouro: any;
  public Numero: any;
  public Bairro: any;
  public Complemento: any;
  public Ativo: any;
  public Alteracoes: any;
  public DadosBancarios : string;
  public Observacoes : string;
  public Internacional: any;
  public WhatsAppReserva : any;
  public ContatoReserva : any;
  public DiasAntecedenciaReserva : any;

}

export class OfflineServicoImagens {
  public Id: number ;
  public OfflineServicoId: number;
  public URL: string;
}

export class OfflineServico_Cidade {
  public Cidade?: any = {Nome : ''};
}

export class ServicoOfflineTarifa {
  public Id : number;
  public OfflineServicoId: number;
  public De : any;
  public Ate : any;
  public ValidadeDe : any;
  public ValidadeAte : any;
  public Ativo : any;
  public TarifaFaixasEtarias : ServicoOfflineTarifaFaixaEtaria[];
  public Quantidade : number;
  public Descricao : any;
  public Lote: any;
}

export class OfflineServicoManutencaoPeriodo {
  public Id: number ;
  public OfflineServicoId: number;
  public Descricao: string;
  public PeriodoDe: any;
  public PeriodoAte: any;
}
export class ServicoOfflineTarifaFaixaEtaria {
  public Id : number;
  public OfflineServicoTarifaId : number;
  public Tipo : string;
  public IdadeMinima : number;
  public IdadeMaxima : number;
  public QuantidadeMinima : number;
  public QuantidadeMaxima : number;
  public Valor : number;
}

export class ServicoOfflinePergunta {
  public Id : number;
  public Pergunta : String;
  public Pergunta_Sp : String;
  public Pergunta_En : String;

  public Tipo : number = 0;
  public Opcoes : ServicoOfflinePerguntaOpcao[];
  public Obrigatorio : any = false;
  public OfflineServicoId : number;
  public Ordem : number = 0;
}

export class ServicoOfflinePerguntaOpcao {
  public Id : number;
  public Opcao : String;
  public OfflineServicoPerguntaId : number;
}

export class ServicoOfflineTipo {
  public Id : number;
  public Descricao : String;
  public Descricao_Sp : String;
  public Descricao_En : String;
  public Perguntas : any;
  public Alteracoes: any;
  public ControlaQuantidade : any;
  public AeroportoIn : any;
  public AeroportoOut : any;
  public HotelIn : any;
  public HotelOut : any;
  public ERPId : any;
  public Tipo : number;
}
export class PerguntasPorTipo {
  public Id : number;
  public Pergunta : String;
  public Pergunta_Sp : String;
  public Pergunta_En : String;
  public OfflineServicoTipoId : number;
  public Alteracoes: any;
  public TipoPergunta: any;
  public Ordem: any;
  public Agrupador: any;
  public Obrigatorio: any;
  public DataAutomatica: any;
}

export class MarkupOfflineServico {
  public Id: number;
  public OfflineServico?: any = {Descricao : ''};
  public OfflineServicoId: number;
  public Descricao: string;
  public De : any;
  public Ate : any;
  public MarkupDireto: any;
  public MarkupInternacional: any;


}
