import { Alteracoes } from './base';
import { Cidade } from './endereco.model';
import { Empresa } from './cadastro/empresa.model';
import { Agencia } from './cadastro/agencia.model';
import { PerfilUsuario } from './configuracao/perfil-usuario.model'
import { IntranetGrupo } from './intranet/grupo.model';
import { Dispositivos } from './cadastro/dispositivos.model';

export  class Usuario {
  Nome: string;
  Sobrenome: string;
  Email: string;
  Validade?: any;
  Telefone: string;
  DataNascimento?: any;
  Empresa: Empresa;
  EmpresaId?: any;
  MetaVendaMensal: number;
  Agente: boolean;
  Tentativas: number;
  AgenteAdmin: boolean;
  UsuarioWhiteLabel: boolean;
  TrocarSenha: boolean;
  Agencia: Agencia;
  AgenciaId?: any;
  Perfis: PerfilUsuario[];
  IntranetGrupos: IntranetGrupo[];
  AgenciasConsultor?: any;
  FornecedorHotelServico: any;
  Alteracoes: Alteracoes;
  Cep: string;
  Logradouro: string;
  Numero: string;
  Complemento: string;
  Bairro: string;
  Cidade: Cidade;
  CidadeId: number;
  Id: number;
  Erros?: any;
  Consultor: any = false;
  ConsultorExclusivo: any = false;
  ConsultorExclusivoComercial: any = false;
  ConsultorExclusivoComercialIds: string = '';
  ConsultorApi : any;
  ConsultorApiId: number;
  UsaApi: false;
  DadosBancarios : string;
  Observacoes : string;
  Senha: string;
  IdErp: any;

  AssinaturaDigitalizada: any;
  flAssinaContrato: boolean;
  Cpf: any;
  Rg: any;

  OfflineHoteisVinculados: any;
  OfflineServicoFornecedoresVinculados : any;

  access: string;
  refresh: string;
  tokenExpire: number;

  Empresas: any[];
  DispositivosVinculados: Dispositivos[]

  constructor(){
    this.Cidade = new Cidade();
    this.Agencia = new Agencia();
    this.Empresa = new Empresa();
    this.ConsultorApi = { 'Nome': '' };

  }
}
