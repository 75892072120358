import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Relatorio, WebService } from '../../model';
import { API_RELATORIOS, GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class RelatorioService {

    constructor(private http: HttpClient) { }

    relReceptivoCheckInPorData(fornecedorId: string = '0', checkInInicial: string, checkInFinal: string, chkBoxData: string, fornecedor: string, servico: string, servicoId: string, destino: string, WebServiceId: string = '0', ReservaNaoPaga, consultor:any): Observable<any> {
        const formData = new FormData();
        formData.append('FornecedorId', fornecedorId);
        formData.append('Pagina', '1');
        formData.append('ITENSPORPAGINA', '1000');
        formData.append('De', checkInInicial);
        formData.append('Ate', checkInFinal);
        formData.append('fornecedor', fornecedor);
        formData.append('servico', servico);
        formData.append('destino', destino);
        formData.append('chkBoxData', chkBoxData);
        formData.append('servicoId', servicoId);
        formData.append('WebServiceId', WebServiceId);
        formData.append('ReservaNaoPaga', ReservaNaoPaga);
        formData.append('COnsultor', consultor);
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/relatorioreceptivocheckinpordata`, formData);
    }

    relCheckinPorData(checkInInicial: any, checkInFinal: any, consultor: any, webservice: any): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/checkinpordata`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal, Consultor: consultor , WebService : webservice}, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relHoteis(checkInInicial: any, checkInFinal: any, webservice: any, empresa: any, agencia: any, consultor: any, cidadeid: any, NomeHotelVendido: any): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/hoteisvendidos`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal, WebService: webservice, Empresa: empresa, Agencia: agencia, Consultor: consultor, Cidade: cidadeid, NomeHotelVendido }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relHotelReservadoNaoPago(checkInInicial: any, checkInFinal: any, webservice: any, empresa: any, agencia: any, consultor: any, cidadeid: any): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/hotelreservadonaopago`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal, WebService: webservice, Empresa: empresa, Agencia: agencia, Consultor: consultor, Cidade: cidadeid }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relStatusReservasHoteis(checkInInicial: any, checkInFinal: any): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/statusreservashoteis`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relAereoPago(datainicial, datafinal): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/RelatorioAereoPago?DataInicial=${datainicial}&DataFinal=${datafinal}`, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relAereosOrcamentos(De: any, Ate: any, Status: any, Localizador: any): Observable<any> {
        const headers = new HttpHeaders().append('noloader', 'noloader');
        let Filtros = { DataInicial: De, DataFinal: Ate, Status: Status, LocalizadorAereo: Localizador };
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/AereosOrcamentos`, Filtros, { headers: headers });
    }

    relStatusReservasAereos(checkInInicial: any, checkInFinal: any): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/statusreservasaereos`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relAereosPax(De: any, Ate: any, Status: any, Localizador: any, Origem: any, Destino: any, FornecedorAereoSigla: any, TipoData: string, consultor: any): Observable<any> {
        const headers = new HttpHeaders().append('noloader', 'noloader');
        let Filtros = { DataInicial: De, DataFinal: Ate, Status: Status, LocalizadorAereo: Localizador, Origem: Origem, Destino: Destino, Fornecedor: FornecedorAereoSigla, TipoData: TipoData, ConsultorId: consultor };
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/aereopax`, Filtros, { headers: headers });
    }

    relDeCarros(De: any, Ate: any, StatusReserva: number, StatusCarro: number): Observable<any> {
        const headers = new HttpHeaders().append('noloader', 'noloader');
        let Filtros = { DataInicial: De, DataFinal: Ate, StatusOrcamento: StatusReserva, StatusCarro: StatusCarro };
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/relatoriodecarros`, Filtros, { headers: headers });
    }

    relDeBrindes(AgenciaId: any, Status: any, De: any, Ate: any): Observable<any> {
        let Filtros = { DataInicial: De, DataFinal: Ate, StatusOrcamento: Status, AgenciaId: AgenciaId };
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/relatoriodebrindes`, { Filtros })
    }

    relDeCircuitos(Checkin, CheckOut, Localizador, StatusCircuito: any = -1, StatusOrcamento: any = -1): Observable<any> {
        return this.http.get<any>(`${API_RELATORIOS}/api/relatorios/relatoriodecircuitos?DataInicial=${Checkin}&DataFinal=${CheckOut}&Localizador=${Localizador}&StatusCircuito=${StatusCircuito}&StatusOrcamento=${StatusOrcamento}`);
    }

    relNoitesVendidasCampanhas(CampanhaEtapaId: number, EmpresaId: number, AgenciaId: number): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/relatoriodenoitesvendidascampanha`, { CampanhaEtapaId: CampanhaEtapaId, EmpresaId: EmpresaId, AgenciaId: AgenciaId });
    }

    relOrcamentosPagos(dataInicial: any, dataFinal: any, comercial: any, empresa: any, agencia: any, consultor: any, agente: any, webservice: any, ignoraRemarcacao: boolean): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/orcamentospagos/`, { DataInicial: dataInicial, DataFinal: dataFinal, Comercial: comercial, Empresa: empresa, Agencia: agencia, Consultor: consultor, Agente: agente, WebServiceId: webservice, IgnoraRemarcacao: ignoraRemarcacao }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relOrcamentosPagosFormaPagamento(dataInicial: any, dataFinal: any, comercial: any, empresa: any, agencia: any, consultor: any, agente: any, webservice: any, tipoPagamento: any): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/orcamentospagosformapagamento/`, { DataInicial: dataInicial, DataFinal: dataFinal, Comercial: comercial, Empresa: empresa, Agencia: agencia, Consultor: consultor, Agente: agente, WebServiceId: webservice, TipoPagamento: tipoPagamento }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relOrcamentosGerados(dataInicial: any, dataFinal: any, comercial: any, empresa: any, agencia: any, consultor: any, agente: any, webservice: any, pagina: number): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/orcamentosgerados?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { DataInicial: dataInicial, DataFinal: dataFinal, Comercial: comercial, Empresa: empresa, Agencia: agencia, Consultor: consultor, Agente: agente, WebServiceId: webservice, pagina: pagina }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relDePagamentos(dataInicial, dataFinal, statusReserva, statusPagamento, tipoPagamento, statusFinanciamento, statusPix): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/relatoriodepagamentos/`, { DataInicial: dataInicial, DataFinal: dataFinal, StatusOrcamento: statusReserva, StatusPagamento: statusPagamento, TipoPagamento: tipoPagamento, StatusFinanciamento: statusFinanciamento, StatusPix: statusPix }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relOrcamentosNaoPagos(DataInicial: any, DataFinal: any, Comercial: any, Empresa: any, Agencia: any, Consultor: any, Agente: any, ItemReserva: string = "Todos", WebServiceId: any, StatusReserva: number): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/orcamentosnaopagos/`, { DataInicial, DataFinal, Comercial, Empresa, Agencia, Consultor, Agente, ItemReserva, WebServiceId, StatusReserva }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relAereosLatam(DataInicial: any, DataFinal: any): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/relatorioaereoslatam`, { DataInicial, DataFinal }, { headers: new HttpHeaders().set('noloader', 'true') });
    }












    //#region Modelo Antigo
    buscarPorParametro(param: string, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        if (param.length > 0) {
            return this.http.get<any>(`${GERENCIAMENTO}/api/Relatorio/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        } else {
            return this.http.get<any>(`${GERENCIAMENTO}/api/Relatorio?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>(`${GERENCIAMENTO}/api/Relatorio/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/Relatorio/${id}`);
    }

    salvar(relatorio: Relatorio): Observable<any> {
        if (relatorio.Id > 0) {
            return this.http.put<any>(`${GERENCIAMENTO}/api/Relatorio/${relatorio.Id}`, relatorio);
        } else {
            return this.http.post<any>(`${GERENCIAMENTO}/api/Relatorio/`, relatorio);
        }
    }

    todosRelatorios(): Observable<any> {
        return this.http.get<any>(`${GERENCIAMENTO}/api/relatorios/todas/1`);
    }
    //#endregion
}
