import { Cidade } from '../endereco.model'
import { Any } from 'json2typescript';

export class Hotel {
  public Id: number;
  public selecionado: boolean;
  public Recomendado: boolean;
  public Nome: string;
  public Fornecedor: string;
  public Localizador: string;
  public Status: number;
  public CodigoFornecedor: any;
  public ExpandirAccordion = false;
  public BloqueiaAccordion = true;
  public MesmaPensao: boolean;
  public ExigePrePagamento: boolean;
  public PrePagamentoFornecedor: boolean;
  public Latitude: number;
  public Longitude: number;
  public Estrelas: number;
  public Cidade: Cidade;
  public Endereco: any;
  public Checkin: any;
  public Checkout: any;
  public DataReserva: any;
  public Quartos: any;
  public MostraIsencaoIrrf : any;
  public QuantidadeSelecionada : number;
  public Detalhes : DetalhesHotel;
  public Offline : any;
  public PossuiQuartoIsento : any;
  public AvisoMinNoites : any;
  public Internacional : any;
  public Desconto : any;
  public opcoes : any;
  public Observacoes: any;
  public ValorTotalVenda: any;
  public ValorTotalNet: any;
  public QuartosPorAgrupador;
  public HotelImagem;
  public SiglaMoeda;
  public Agrupador: any;
  public AgrupamentoFornecedor: any;
  public Opcao: number;
}

export class DetalhesHotel {
  public Descricao: string;
  public Facilidades: any[] = [];
  public Imagens: string[] = [];
}

export class QuartoHotelRQ {

  NumeroPesquisa: number;
  Qtde: number;
  Adultos: number;
  Criancas: number[] = [];
}
export class FiltroHotel {

  public ValorMinimo: number;
  public ValorMaximo: number;
  public EstrelasMinimo: number = 0;
  public EstrelasMaximo: number = 5;
  public Nome: string;
  public Fornecedores: string[] = [];
  public Reembolsavel: number = -1;
  public Pensao: string[] = [];
  public Pensoes: string[] = [];
  public Localizacao: string;
  public Ordenacao: string = "";

}
export class BuscaHotelRQ {
  public Checkin: any;
  public Checkout: any;
  public SomentePromocao: boolean = false
  public HotelCodigo: string;
  public Moeda: string;
  public Quartos: QuartoHotelRQ[] = [];
  public Cidade: Cidade = new Cidade();
  public Guid: string;
  public FiltroHotel: FiltroHotel = new FiltroHotel();
  public Rebusca: boolean;
  public AgenciaId: number;
  public BuscaPacote: boolean = false;
  public BuscaEvento: boolean = false;
  public Nacionalidade: string;
  public PacoteId = 0;
  public EventoId = 0;
}
