export class Aereo {
    public Id: number;
    public CiaValidadora: string;
    public selecionado: boolean;
    public AereoOperacao: boolean;
    public Seguimentos: any;
    public SeguimentosAgrupados: any;
    public Status: number;
    public VendaTotal: number;
    public Fornecedor: string;
    public Subfornecedor: string;
    public ExtraIntegracao: string;
    public ExtraIntegracao2: ExtraIntegracao;
    public PaxesTarifa: any;
    public TarifasTotal: any;
    public TaxasTotal: any;
    public LocalizadorAereo: any;
    public LimiteTempoEmissao: any;
    public ValidadeTarifa: any;
    public Erros: any;
    public MoedaListagem: any;
    public MoedaFechamento: any;
    public TarifasListagemTotal: any;
    public TarifasFechamentoTotal: any;
    public TaxasListagemTotal: any;
    public TaxasDUListagemTotal: any;
    public TaxasFechamentoTotal: any;
    public TaxasDUFechamentoTotal: any;
    public ValorListagem: any;
    public ValorFechamento: any;
    public BuscaPacote: any;
    public TempoPorSeguimento: any;
    public MelhorFamilia: boolean = false;
    public MelhorTarifa: boolean = false;
    public Chd: number;
    public Inf: number;
    public valido: boolean = true;
}

class ExtraIntegracao {
    public Disponivel: number;
    public Reservado: number;
    public Emitido: number;
}
export class Segmento {
    public AeroportoPartida: string;
    public AeroportoChegada: string;
    public DataPartida: Date;
    public PaisChegada: any;
}

export class BuscaAereoRQ {
    public Adt: number;
    public Chd: number;
    public Inf: number;
    public Idades: string;
    public SegmentosBusca: Segmento[];
    public Guid: string;
    public Rebusca: boolean;
    public ReservaId: number;
    public FiltroAereo: FiltroAereo = new FiltroAereo();
    public AgenciaId: number;
    public MaximoRegistros: number;
    public TipoBusca: string;
    public BuscaPacote: boolean;
    public EscreveLog: boolean = false;
    public PacoteId = 0;
}

export class FiltroAereo {
    public ValorMinimo: number;
    public ValorMaximo: number;
    public HorarioIdaMinimo: number = 0;
    public HorarioIdaMaximo: number = 23;
    public HorarioVoltaMinimo: number = 0;
    public HorarioVoltaMaximo: number = 23;
    public SomenteVoosDiretos: boolean;
    public ConexaoLonga: boolean;
    public Cias: string[] = [];
    public Aeroportos: string[] = [];
    public Voos: number[];
    public Bagagem: number = -1;
    public TodasFamilias: boolean = true;
    public Tarifa: string[];
    public Fornecedores: string[] = [];
    public Familia: string[] = [];
    public MinimoDuracaoTrechos: any = [];
    public MaximoDuracaoTrechos: any = [];
    public NumeroParadasIda: number = -1;
    public NumeroParadasVolta: number = -1;
    public Ordenacao: string = "asc";

}
