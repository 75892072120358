import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

import { API, CLIENT_ID } from '../app.config';
import { RetornoAutenticacao } from '../model/base';
import { FingerprintService } from './fingerprint.service';

var geolocalizacao: any = false;
@Injectable()
export class LoginService implements OnInit {
    private tempUser: any = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private fingerprintService: FingerprintService
    ) {
        fingerprintService = new FingerprintService();
        navigator.geolocation.getCurrentPosition(this.getGeoLocalizacao);
    }

    ngOnInit() {
        navigator.geolocation.getCurrentPosition(this.getGeoLocalizacao);
    }

    today(): Date {
        return new Date();
    }

    user(): any {
        const usuario = JSON.parse(window.localStorage.getItem('loggedUser'));
        const agora = Date.now() / 1000;
        // const currentUrl = this.router.url;
        if (usuario === null) {
            this.handleLogin('');
            return null;
        } else if (usuario.portal && usuario.portal != window.location.pathname.split('/')[1]) {
            this.logout();
        }
        if (agora < usuario.token_expire) {
            return usuario;
        } else if (usuario != null) {
            this.logout();
        }
    }

    isLoggedIn() {
        return this.user() != null;
    }

    isLoggedInValidator(): boolean {
        let usuario = this.user();
        if (usuario != null) {
            const agora = Date.now() / 1000;
            return false;
        } else {
            return false;
        }
    }

    handleLogin(caminho: string) {
        // this.router.navigate(['/login', btoa(caminho)])
        //const currentUrl = this.router.url;
        let currentUrl = window.location.hash;
        if (!currentUrl.startsWith('#/contrato')) {
            let hash: string = window.location.hash;
            if (hash.includes("pt")) {
                this.router.navigate(['/auth/login/pt']);
            }
            else if (hash.includes("en")) {
                this.router.navigate(['/auth/login/en']);
            }
            else if (hash.includes("es")) {
                this.router.navigate(['/auth/login/es']);
            }
            else {
                this.router.navigate(['/auth/login/pt']);
            }
        }

    }

    getGeoLocalizacao(position) {
        geolocalizacao = position;
    }

    setTempUser(user: any) {
        this.tempUser = user;
    }

    getTempUser() {
        return this.tempUser;
    }

    clearTempUser() {
        this.tempUser = null;
    }

    clearFingerPrint() {
        this.fingerprintService.clearFingerPrint();
    }

    // logar(username: string, password: string): Observable<any> {
    logar(username: string, password: string): Observable<any> {
        let headers = new HttpHeaders({
            'Content-type': 'application/json',
            'noauth': 't',
        });
        if (geolocalizacao) {
            headers = headers.set('latitude', String(geolocalizacao.coords.latitude));
            headers = headers.set('longitude', String(geolocalizacao.coords.longitude));
        }
        this.fingerprintService.getOrCreateFingerprint(username);
        const browserName = this.getBrowserName(navigator.userAgent);
        headers = headers
            .set('fingerprint', this.fingerprintService.getFingerprint())
            .set('navegador', browserName);

        const body = `grant_type=password&username=${username}&password=${password}&client_id=${CLIENT_ID}`;

        return this.http.post<any>(`${API}/token`, body, { headers: headers, observe: "response" });
    }

    public buscarDadosUsuario(usuario) {
        delete usuario.Nome;
        window.localStorage.setItem('loggedUser', JSON.stringify(usuario));
        this.getUserById(usuario.Id).subscribe(
            dados => {
                let user = dados.Items[0];
                delete user.Perfis;
                delete user.Roles;
                delete user.Alteracoes;
                delete user.Cidade.Alteracoes;
                // delete user.Agencia;

                if (window.location.host == 'ark.tur.br' && window.location.pathname.split('/')[1]) {
                    user.portal = window.location.pathname.split('/')[1];
                }

                user.access_token = usuario.access_token;
                user.token_expire = usuario.token_expire;
                window.localStorage.setItem('loggedUser', JSON.stringify(user));
            }, erro => {
                console.log("ERRO AO BUSCAR DADOS DO USUARIO");
                console.log(erro);
                this.buscarDadosUsuario(usuario);
            }
        );
    }

    logout() {
        window.localStorage.clear();
        window.sessionStorage.clear();
        this.handleLogin('');
    }

    getUserById(id: number): Observable<any> {
        return this.http.get<any>(`${API}/api/usuario/${id}?Pagina=1&ItensPorPagina=1`);
    }

    recuperarSenha(obj: any): Observable<any> {
        return this.http.patch<any>(
            `${API}/api/usuario/recuperarSenha`,
            obj
        );
    }

    verificarCodigoAutenticacao(codigo: string): Observable<RetornoAutenticacao> {
        let headers = new HttpHeaders().set('noauth', 't');
        if (geolocalizacao) {
            headers = headers.append('latitude', geolocalizacao.coords.latitude);
            headers = headers.append('longitude', geolocalizacao.coords.longitude);
        }
        this.fingerprintService.getOrCreateFingerprint(this.tempUser.Email);
        headers = headers.append('navegador', this.getBrowserName(navigator.userAgent));

        return this.http.get<RetornoAutenticacao>(`${API}/api/autenticacao/validaracesso/${this.tempUser.Id}/${this.fingerprintService.getFingerprint()}/${codigo}`, { headers: headers });
    }

    private getBrowserName(userAgent) {
        if (userAgent.includes("Firefox")) {
            return "Mozilla Firefox";
        } else if (userAgent.includes("SamsungBrowser")) {
            return "Samsung Internet";
        } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
            return "Opera";
        } else if (userAgent.includes("Edge")) {
            return "Microsoft Edge (Legacy)";
        } else if (userAgent.includes("Edg")) {
            return "Microsoft Edge (Chromium)";
        } else if (userAgent.includes("Chrome")) {
            return "Google Chrome or Chromium";
        } else if (userAgent.includes("Safari")) {
            return "Apple Safari";
        } else {
            return "unknown";
        }
    }
}
