import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { Pacote, PacoteTipo, BuscaHotelRQ, OfflineApartamento } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA, TURISMO_HOTEL, API,API_RELATORIOS } from '../../app.config';

@Injectable()
export class PacoteService {

  constructor(private http: HttpClient) { }

  listaPacotesPagos(id: number): Observable<any> {
    return this.http.get<any>(
      `${GERENCIAMENTO}/api/pacote/pagos/${id}`
    );
  }

  buscarPorParametro(param: string, pagina: Number, ativo: boolean = true, tipoPacote: string = "Todos"): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${GERENCIAMENTO}/api/Pacote/listerp/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&ativo=${ativo}&tipoPacote=${tipoPacote}`, { headers: headers });
    }
    else {
      return this.http.get<any>(`${GERENCIAMENTO}/api/Pacote/listerp?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&ativo=${ativo}&tipoPacote=${tipoPacote}`, { headers: headers });
    }
  }

  buscarPacoteTipoPorParametro(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${GERENCIAMENTO}/api/pacote/tipos/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }
    else {
      return this.http.get<any>(`${GERENCIAMENTO}/api/pacote/tipos?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }

  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/Pacote/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/Pacote/${id}`);
  }

  removerPacoteReserva(ReservaId: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/Pacote/Reserva/${ReservaId}`);
  }

  removerPacoteReservaEstatico(PacoteReservaId: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/pacotereserva/remover/${PacoteReservaId}`);
  }

  salvar(Pacote: Pacote): Observable<any> {
    if (Pacote.Id > 0) {
      return this.http.put<any>(`${GERENCIAMENTO}/api/Pacote/${Pacote.Id}`, Pacote);
    }
    else {
      return this.http.post<any>(`${GERENCIAMENTO}/api/Pacote/`, Pacote);
    }

  }

  salvarTipo(PacoteTipo: PacoteTipo): Observable<any> {
    if (PacoteTipo.Id > 0) {
      return this.http.put<any>(`${GERENCIAMENTO}/api/pacotetipo/${PacoteTipo.Id}`, PacoteTipo);
    }
    else {
      return this.http.post<any>(`${GERENCIAMENTO}/api/pacotetipo`, PacoteTipo);
    }

  }

  removerTipo(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/PacoteTipo/remover/${id}`);
  }

  buscarTipoPorId(id: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/pacote/tipos/update/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  uploadImagemPrincipal(arquivo: any, id: number): Observable<any> {

    const url = `${GERENCIAMENTO}/api/pacote/imagemprincipal/${id}/`;
    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);

    return this.http.post(url, formData, { headers: headers });
  }

  uploadImagem(arquivo: any, id: number): Observable<any> {

    const url = `${GERENCIAMENTO}/api/pacote/imagem/${id}/`;

    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);

    return this.http.post(url, formData, { headers: headers });

  }

  removerImagem(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/pacote/imagem/remover/${id}`);
  }


  vincularServicoOffline(id: number, OfflineServicoId: number): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/pacote-offlineservico/vincular/${id}/${OfflineServicoId}`, {});
  }

  desvincularServicoOffline(id: number, OfflineServicoId: number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/pacote-offlineservico/desvincular/${id}/${OfflineServicoId}`, {});
  }

  relPacotes(checkInInicial: any, checkInFinal: any, pacote: any, referencia: any): Observable<any> {
    return this.http.post<any>(`${API_RELATORIOS}/api/pacote/pacotesvendidos`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal, Pacote: pacote, Referencia: referencia }, { headers: new HttpHeaders().set('noloader', 'true') });
  }
  relPacotesLista(checkInInicial: any, checkInFinal: any, pacote: any, referencia: any): Observable<any> {
    return this.http.post<any>(`${API_RELATORIOS}/api/pacote/pacoteslista`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal, Pacote: pacote, Referencia: referencia }, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  buscaDestinos(): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/pacote/destinos`);
  }
  //Buscador de pacotes
  buscaPacotesDisney(AgenciaId: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/pacote/disney/${AgenciaId}`);
  }

  buscaPacotesDisneyPorId(id: number, AgenciaId: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/pacote/disney/${id}/${AgenciaId}`);
  }

  buscaPacotesDestinoPorId(id: number, AgenciaId: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/pacotedestino/destino/${id}/${AgenciaId}`);
  }
  buscaPacotesDestinos(DestinoId: number, pagina: number, parametro: string = ""): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/PacoteDestino/destinos/${DestinoId}?Parametro=${parametro}&Pagina=${pagina}&ItensPorPagina=12`);
  }
  buscaPacotesDestinosDisney(DestinoId: number, pagina: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/PacoteDestino/destinosdisney/${DestinoId}?Pagina=${pagina}&ItensPorPagina=12`);
  }
  buscarPacotesDestinoCasamentos(DestinoId: number, pagina: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/PacoteDestino/destinoscasamento/${DestinoId}?Pagina=${pagina}&ItensPorPagina=12`);
  }
  buscaPacotesDestinosPromocoes(DestinoId: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/PacoteDestino/destinos/promocoes/${DestinoId}`);
  }
  gerarOrcamentoDisney(pacote: any, AgenciaId: number): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/pacote/disney/gerarOrcamento/${AgenciaId}`, pacote);
  }
  gerarOrcamentoPacoteDestino(pacote: any, AgenciaId: number): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/pacote/destino/gerarOrcamento/${AgenciaId}`, pacote);
  }
  reservarPacoteDisney(pacotes: any[]): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/pacote/disney/reservar`, pacotes);
  }
  reservarPacoteDestino(PacoteReservaId: number): Observable<any> {
    return this.http.post<any>(`${API}/api/pacoteestatico/reservar/${PacoteReservaId}`, {});
  }

  cancelarPacoteDisney(pacotes: any[]): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/pacote/disney/cancelar`, pacotes);
  }

  cancelarPacoteDestino(PacoteReservaId: number): Observable<any> {
    return this.http.post<any>(`${API}/api/pacote/cancelar/${PacoteReservaId}`, {});
  }

  vincularTipo(id: number, PacoteTipoId: number): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/pacote-tipo/vincular/${id}/${PacoteTipoId}`, {});
  }

  desvinculaTipo(id: number, PacoteTipoId: number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/pacote-tipo/desvincular/${id}/${PacoteTipoId}`, {});
  }

  vinculaAgencia(id: number, agenciaId: number): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/pacote-agencia/vincular/${id}/${agenciaId}`, {});
  }

  desvincularAgencia(id: number, agenciaId: number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/pacote-agencia/desvincular/${id}/${agenciaId}`, {});
  }

  vincularConsultor(id: number, consultorId: number): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/pacote-consultor/vincular/${id}/${consultorId}`, {});
  }

  desvincularConsultor(id: number, consultorId: number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/pacote-consultor/desvincular/${id}/${consultorId}`, {});
  }

  //listagem pacotes

  buscar(busca: BuscaHotelRQ): Observable<any> {
    return this.http.post<any>(`${TURISMO_HOTEL}/api/pacote/busca/`, busca, { headers: new HttpHeaders() });
  }
  buscarEstatico(busca: BuscaHotelRQ): Observable<any> {
    return this.http.post<any>(`${TURISMO_HOTEL}/api/pacote/buscaestatico/`, busca, { headers: new HttpHeaders() });
  }

  manutencaoPacote(id: number, pacoteReserva): Observable<any> {
    return this.http.put<any>(`${GERENCIAMENTO}/api/pacote/Manutencao/${id}`, pacoteReserva, { headers: new HttpHeaders()});
  }

  vinculaApartamentos(id: number, apartamentosIds: number[]): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/pacote/${id}/vincularapartamentos`, apartamentosIds);
  }

  desvinculaApartamentos(id: number, apartamentoId: number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/pacote/${id}/desvincularapartamento/${apartamentoId}`);
  }
}
