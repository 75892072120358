import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FingerprintService {
    private storageKey = 'b57e8c2fb6a7c1d3790ws';
    private fingerprint: string;

    constructor() {
        this.generateFingerprint().then(fingerprintResult => {
            this.saveFingerprint(fingerprintResult);
            this.setFingerPrint(fingerprintResult);
        });
    }

    public getFingerprint(): string {
        return this.fingerprint;
    }

    public setFingerPrint(fingerprint: string): void {
        this.fingerprint = fingerprint;
    }

    clearFingerPrint() {
        this.fingerprint = null;
    }

    // Método público para obter ou gerar o fingerprint
    public getOrCreateFingerprint(username: string): string {
        username = username.split('@')[0];

        // 1. Verificar no localStorage
        let fingerprint = localStorage.getItem(this.storageKey);
        if (fingerprint) {
            this.setFingerPrint(`${username}-${fingerprint}`);
            return `${username}-${fingerprint}`;
        }

        // 2. Gerar um novo fingerprint
        this.generateFingerprint().then(fingerprintResult => {
            fingerprint = fingerprintResult;
            this.saveFingerprint(fingerprint);
            this.setFingerPrint(fingerprint);
            return `${username}-${fingerprint}`;
        });
    }

    // Método privado para salvar o fingerprint no localStorage
    private saveFingerprint(fingerprint: string): void {
        localStorage.setItem(this.storageKey, fingerprint);
    }

    // Método privado para gerar um fingerprint
    private async generateFingerprint(): Promise<string> {
        const gpuFingerprint = await this.getGPUFingerprint();
        const data = {
            userAgent: navigator.userAgent,
            language: navigator.platform,
            vendor: navigator.vendor,
            navigator: navigator.language,
            timezonaOffset: new Date().getTimezoneOffset().toString(),
            hostname: window.location.hostname,
            colorDepth: screen.colorDepth,
            gpuFingerprint,
        };

        return await this.hashString(JSON.stringify(data));
    }

    // Método para criar um hash (SHA-256)
    private async hashString(data: string): Promise<string> {
        const encoder = new TextEncoder();
        const dataBuffer = encoder.encode(data);

        // A API `crypto.subtle.digest` retorna um `PromiseLike`, convertemos para uma `Promise`
        const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);

        // Converta o resultado em uma string hexadecimal
        return Array.from(new Uint8Array(hashBuffer))
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
    }

    private async getGPUFingerprint(): Promise<string> {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

        if (!gl) return 'No WebGL';

        const debugInfo = (gl as WebGLRenderingContext).getExtension('WEBGL_debug_renderer_info');
        if (!debugInfo) return 'No Debug Info';

        return (gl as WebGLRenderingContext).getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) || 'Unknown GPU';
    }
}
