import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { OfflineApartamentoDisponibilidade } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class OfflineApartamentoDisponibilidadeService {

    constructor(private http: HttpClient) { }

    remover(id: Number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/OfflineApartamentoDisponibilidade/${id}`);
    }

    salvar(offlineApartamentoDisponibilidade: OfflineApartamentoDisponibilidade[], DisponibilidadePeriodoAte: any, DisponibilidadePeriodoDe: any): Observable<any> {

        return this.http.post<any>(`${GERENCIAMENTO}/api/OfflineApartamentoDisponibilidade`, { Disponibilidades: offlineApartamentoDisponibilidade, DisponibilidadePeriodoAte: DisponibilidadePeriodoAte, DisponibilidadePeriodoDe: DisponibilidadePeriodoDe });
    }

    MontaCalendarioDisponibilidade(hotelid: number, ano: number, mes: number, periodo: number, pacoteReferencia: string = ""): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');

        return this.http.post<any>(`${GERENCIAMENTO}/api/OfflineApartamentoDisponibilidade/list?HotelId=${hotelid}&Ano=${ano}&Mes=${mes}&Periodo=${periodo}&referencia=${pacoteReferencia}`, { headers: headers });

    }
    MontaCalendarioDisponibilidadePeriodo(hotelid: number, de: any, ate: any, categoriaId: number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');

        return this.http.post<any>(`${GERENCIAMENTO}/api/OfflineApartamentoDisponibilidade/list/?HotelId=${hotelid}&De=${de}&Ate=${ate}&CategoriaId=${categoriaId}`, { headers: headers });

    }
    ListarDisponibilidadePeriodo(Hotelid: Number, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');

        return this.http.get<any>(`${GERENCIAMENTO}/api/OfflineApartamentoDisponibilidadePeriodo/list/${Hotelid}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    // buscarPorParametro(param: string, pagina: Number, idcategoria : Number, datainicial : any = "", datafinal : any = ""): Observable<any>{
    //   const headers = new HttpHeaders().set('noloader', 't');
    //   if (param.length > 0){
    //     return this.http.get<any>( `${GERENCIAMENTO}/api/OfflineApartamentoTarifa/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&IdCategoria=${idcategoria}&DataInicial=${datainicial}&DataFinal=${datafinal}`, {headers: headers});
    //   }else{
    //     return this.http.get<any>( `${GERENCIAMENTO}/api/OfflineApartamentoTarifa?&Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&IdCategoria=${idcategoria}&DataInicial=${datainicial}&DataFinal=${datafinal}`, {headers: headers});
    //   }
    // }

}
