

const API = "https://api.comprefacil.tur.br";
// const API = "http://localhost:62672";

export const environment = {
    ASSISTCARDLATAM: false,
    API: API,
    production: true,
    PAYPAL_CLIENT_ID: "",
    ambiente: "FRT",
    THEME: "FRT", //(FRT - ARKBEDS - FRT-BLACK)
    //Módulos de turismo
    VALIDAPRAZOCANCELAMENTOMANUTENCAO: false,
    USA_CASAMENTO: true,
    USA_CONTRATO: true,
    USA_CIRCUITO: true,
    USA_TURISMO: true,
    USA_PACOTE: true,
    USA_SEGURO: true,
    USA_DISNEY: true,
    USA_CARRO: true,
    USA_AEREO: true,
    USA_CASA: true,
    USA_HOTEL: true,
    USA_BETO: true,
    USA_PROMOCIONAL: true,
    USA_MARKUPIMPRESSAO: false,
    USA_CADASTROAGENCIA: false,
    USA_LOJA: true,
    USA_ARKBEDS: false,
    USA_TERMOMETRO: true,
    USA_SOLICITARDESCONTO: true,
    USA_CADASTRO_CIRCUITO: false,
    USA_CADASTRO_APARTAMENTO_EXCLUSIVO: false,
    //Apis dos módulos de turismo
    TURISMO: API,
    TURISMO_AEREO: "https://apiaereo.comprefacil.tur.br",
    TURISMO_HOTEL: "https://apihotel.comprefacil.tur.br",
    TURISMO_SERVICO: API,
    TURISMO_SEGURO: API,
    GERENCIAMENTO: API,
    TURISMO_CARRO: API,
    EMPRESAS: API,

    //Módulos Adicionais
    USA_BLOQUEIO_FRT: false, // Permite o uso de um botão adicional na aba bloqueios
    USA_INTRANET: true, // Permite o uso da Intranet no portal
    USA_LAMINAS: true, // Permite gerar Lâminas no portal
    USA_BANNERS: true, //Permite o uso de Banners

    //Configurações
    USA_PACOTESIMPLIFICADO: false,
    CLIENT_ID: "postman:123",
    MAPBOX_ACCESSTOKEN: 'pk.eyJ1IjoiZnJ0b3BlcmFkb3JhIiwiYSI6ImNsN3E5a3N0YTAycm00MW8wd2J5NXFoMjIifQ.NDfY7OK_ygHR567e-TVVcw',
    ITENSPORPAGINA: 20,
    MAXIMO_QUARTOS: 8,
    TAG_GOOGLE: 'GTM-NDQJC4N',
    URLSITE: 'https://comprefacil.tur.br'
};
