<div class="form-group">

  <label *ngIf="label?.length > 0" class="label"> {{label}}</label>
  <ng-content></ng-content>
  <span class="help-block-error"  *ngIf="(input.touched || input.dirty) && input.errors?.required"> - {{'requiredMessage' | translate}}</span>
  <span class="help-block-error"  *ngIf="(input.touched || input.dirty) && input.errors?.minlength"> - {{'Preenchimento mínimo' | translate}}: {{input.errors?.minlength.actualLength}}/{{input.errors?.minlength.requiredLength}}</span>
  <span class="help-block-error"  *ngIf="(input.touched || input.dirty) && (input.errors?.email || input.errors?.cpf || input.errors?.cnpj || input.errors?.pattern )"> - {{'errorMessage' | translate}}</span>
  <span class="help-block-error"  *ngIf="(input.touched || input.dirty) && input.errors?.min"> - {{'Valor deve ser no mínimo' | translate}}: {{input.errors?.min.min}}</span>



</div>
